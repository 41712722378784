import React, { useState } from 'react';
import ApprenticeOverview from './apprenticeOverview';
import NewApprenticePopup from './newApprenticePopup';

const ApprenticePage = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="bg-theme-50 h-full">
      {isPopupVisible ? (
        <NewApprenticePopup onClose={togglePopup} />
      ) : (
        <ApprenticeOverview onNewTaskClick={togglePopup} />
      )}
    </div>
  );
};

export default ApprenticePage;
