import React, { useEffect, useState } from 'react';
import DefaultButton from '../components/button';
import { useTask } from '../../common/hooks/api';

interface TaskOverviewProps {
  onNewTaskClick: () => void;
}

const TaskOverview = ({ onNewTaskClick }: TaskOverviewProps) => {
  const { getAllTasks } = useTask();
  const [tasks, setTasks] = useState<string[]>([]);

  useEffect(() => {
    getAllTasks()
      .then(fetchedTasks => {
        setTasks(fetchedTasks);
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  }, []);

  return (
    <div className="flex">
      <div className="container mx-auto">
        <div className="lg:mx-72 md:mx-32 sm:mx-24 mx-4 mt-8">
          <div className="bg-center bg-clip-border h-96 relative">
            <div className="flex justify-between items-center">
              <p className="text-theme-300 font-primary font-bold select-none text-2xl">Ämtli Übersicht</p>
              <DefaultButton onClick={onNewTaskClick} text="Neues Ämtli hinzufügen" />
            </div>
            <div className="flex flex-wrap gap-2 mt-4 ">
              {tasks.length === 0 ? (
                <div
                  className="px-5 py-2 font-primary
                  text-theme-300/50 rounded-lg border-theme-300/50 border-dashed border select-none">
                  Es gibt noch keine Ämtlis!
                </div>
              ) : (
                tasks.map((task, index) => (
                  <div
                    key={index}
                    className="bg-theme-200 px-5 py-2 font-primary
                    text-white rounded-lg shadow-sm border-solid border-theme-300/25 border select-none"
                  >
                    {task}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskOverview;
