import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole, useAuth } from '../common';
import Loading from './components/loadingSpinner'

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles?: UserRole[];
}

const ProtectedRoute = ({ children, requiredRoles = [] }: ProtectedRouteProps) => {
  const { isAuthenticated, roles, isLoading } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const hasRequiredRoles =
    requiredRoles.some(role => roles.includes(role));

  if (!hasRequiredRoles) {
    return <Navigate to="/forbidden" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
