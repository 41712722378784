import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  ArrowRightStartOnRectangleIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  HomeIcon,
  ClockIcon,
  AcademicCapIcon
} from '@heroicons/react/24/solid';
import { HeaderItem } from './headerItem';
import { UserRole, useAuth } from '../common';

export enum ActiveView {
  home,
  statistic,
  absence,
  task,
  calendar,
  addApprentice
}

export function AppHeader () {
  const [activeView, setActiveView] = useState<ActiveView>(ActiveView.home);
  const location = useLocation();
  const { roles, logout } = useAuth();
  const exitButtonColor = 'text-theme-300';

  useEffect(() => {
    switch (location.pathname) {
    case '/statistic':
      setActiveView(ActiveView.statistic);
      break;
    case '/absence':
      setActiveView(ActiveView.absence);
      break;
    case '/task':
      setActiveView(ActiveView.task);
      break;
    case '/calendar':
      setActiveView(ActiveView.calendar);
      break;
    case '/apprentice':
      setActiveView(ActiveView.addApprentice);
      break;
    default:
      setActiveView(ActiveView.home);
      break;
    }
  }, [location.pathname]);

  return (
    <div className={'py-3 bg-theme-50'}>
      <div className="container mx-auto flex justify-between items-center px-8">
        <div className="flex space-x-8">
          <NavLink to="/" onClick={() => setActiveView(ActiveView.home)}>
            <HeaderItem active={activeView === ActiveView.home}
              icon={<HomeIcon className="h-10 w-16" />} text="Home" activeView={activeView} />
          </NavLink>
          {roles.some(role => role === UserRole.Educator) ? (
            <>
              <NavLink to="/statistic" onClick={() => setActiveView(ActiveView.statistic)}>
                <HeaderItem active={activeView === ActiveView.statistic}
                  icon={<ChartBarIcon className="h-10 w-16" />} text="Statistiken" activeView={activeView} />
              </NavLink>
              <NavLink to="/absence" onClick={() => setActiveView(ActiveView.absence)}>
                <HeaderItem active={activeView === ActiveView.absence}
                  icon={<ClockIcon className="h-10 w-16" />} text="Absenz" activeView={activeView} />
              </NavLink>
              <NavLink to="/task" onClick={() => setActiveView(ActiveView.task)}>
                <HeaderItem active={activeView === ActiveView.task}
                  icon={<ClipboardDocumentListIcon className="h-10 w-16" />} text="Ämtli"
                  activeView={activeView} />
              </NavLink>
              <NavLink to="/apprentice" onClick={() => setActiveView(ActiveView.addApprentice)}>
                <HeaderItem active={activeView === ActiveView.addApprentice}
                  icon={<AcademicCapIcon className="h-10 w-16" />} text="Lernende" activeView={activeView} />
              </NavLink>
            </>
          ) :
            (
              <NavLink to="/calendar" onClick={() => setActiveView(ActiveView.calendar)}>
                <HeaderItem active={activeView === ActiveView.calendar}
                  icon={<CalendarDaysIcon className="h-10 w-16" />} text="Kalender" activeView={activeView} />
              </NavLink>
            )}
        </div>
        <div>
          <button onClick={() => logout()} className='rounded-2xl transition-all duration-200 hover:bg-theme-200/10'>
            <div className="p-4">
              <ArrowRightStartOnRectangleIcon className={`h-10 w-16" ${exitButtonColor}`} />
              <div className={`font-secondary ${exitButtonColor}`}
              >Exit
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className={'w-full h-px bg-theme-300 mt-4'}></div>
    </div>
  );
}
