import React, { useEffect, useState } from 'react';
import DefaultButton from '../components/button';
import { useApprentice } from '../../common/hooks/api';
import { User } from '../../common';

interface TaskOverviewProps {
  onNewTaskClick: () => void;
}

const ApprenticeOverview = ({ onNewTaskClick }: TaskOverviewProps) => {
  const [apprentices, setApprentices] = useState<User[]>([]);
  const { getApprentices } = useApprentice();

  useEffect(() => {
    getApprentices().then(a => setApprentices(a));
  }, []);

  return (
    <div className="flex">
      <div className="container mx-auto">
        <div className="lg:mx-72 md:mx-32 sm:mx-24 mx-4 mt-8">
          <div className="bg-center bg-clip-border h-96 relative">
            <div className="flex justify-between items-center">
              <p className="text-theme-300 font-primary font-bold select-none text-2xl">Lernenden Übersicht</p>
              <DefaultButton onClick={onNewTaskClick} text="Neue Lernende hinzufügen" />
            </div>
            <div className="flex flex-wrap gap-2 mt-4 ">
              {apprentices.length === 0 ? (
                <div
                  className="px-5 py-2 font-primary
                  text-theme-300/50 rounded-lg border-theme-300/50 border-dashed border select-none">
                  Es gibt noch keine Lernenden!
                </div>
              ) : (
                apprentices.map((apprentice, index) => (
                  <div
                    key={index}
                    className="bg-theme-200 px-5 py-2 font-primary
                    text-white rounded-lg shadow-sm border-solid border-theme-300/25 border select-none"
                  >
                    {apprentice.userName}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprenticeOverview;
