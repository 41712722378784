import React, { useState, useEffect } from 'react';
import ExitButton from '../components/exitButton';
import SaveButton from '../components/saveButton';
import UserSearch from '../components/userSearch';
import { usePersonnel, useApprentice } from '../../common';
import { feedbackToast } from '../components/toasts';
import 'react-day-picker/dist/style.css';

interface NewApprenticePopupProps {
  onClose: () => void;
}

export default function AddApprentice ({ onClose }: NewApprenticePopupProps) {
  const [personnel, setPersonnel] = useState<string[]>([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState<string>('');
  const { getUnregisteredPersonnel } = usePersonnel();
  const { addApprentice } = useApprentice();

  useEffect(() => {
    getUnregisteredPersonnel()
      .then((response: string[]) => setPersonnel(response))
      .catch(_ =>
        feedbackToast({
          variant: 'error',
          message: 'Verfügbare Lernende konnten nicht aus dem Google Workspace geladen werden',
          toastId: 'PersonnelNotLoaded'
        }));
  }, []);

  const saveNewPersonnelAsApprentice = () => {
    if (selectedPersonnel !== '') {
      setPersonnel(personnel.filter(p => p !== selectedPersonnel));
      addApprentice(selectedPersonnel)
        .then(_ => {
          feedbackToast({
            variant: 'success',
            message: 'Lernende wurde hinzugefügt.',
            ctaMessage: 'Rückgängig machen?',
            toastId: 'ApprenticeAdded'
          });
          setSelectedPersonnel('');
        })
        .catch(_ =>
          feedbackToast({
            variant: 'error',
            message: 'Lernende konnte nicht hinzugefügt werden',
            toastId: 'ApprenticeNotAdded'
          }));
    }
  };

  return (
    <div className="flex flex-col items-center text-center bg-theme-50 h-full">
      <div className="relative px-12 pb-4 text-white rounded-3xl bg-theme-300 px-14 ">
        <div className="mb-4 mt-8 text-2xl font-bold font-primary">Neue Lernende Hinzufügen...</div>
        <div className="absolute top-6 right-6">
          <ExitButton onClick={onClose} />
        </div>
        <UserSearch
          emails={personnel}
          value={selectedPersonnel}
          onChange={setSelectedPersonnel}
        />
        <div className="my-4">
          {selectedPersonnel && (<SaveButton onClick={saveNewPersonnelAsApprentice} />)}
        </div>
      </div>
    </div>
  );
}
