import React from 'react';

interface ButtonProps {
  onClick: () => void;
  text: string;
}

const DefaultButton = ({ onClick, text }: ButtonProps) => {
  return (
    <div className="grid ">
      <button
        className="bg-theme-300 text-theme-50 font-secondary w-fit px-6 h-10 rounded-md shadow-sm hover:bg-theme-950 active:outline-none active:ring active:ring-theme-200 hover:scale-[103%] transition-all duration-200"
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default DefaultButton;
