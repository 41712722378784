import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTask } from '../../common';
import SaveButton from '../components/saveButton';
import ExitButton from '../components/exitButton';
import Dropdown from '../components/dropdown';
import InputField from '../components/inputField';
import getCurrentWeekday from './getCurrentWeekday';
import { Size } from './sizeEnum';
import { feedbackToast } from '../components/toasts';

interface InputFieldsState {
  taskName: string;
  requiredPersonnel: string;
  rhythm: string;
  weekday: string;
  description: string;
}

interface NewTaskPopupProps {
  onClose: () => void;
}

const NewTaskPopup = ({ onClose }: NewTaskPopupProps) => {
  const initialInputFieldsState: InputFieldsState = {
    taskName: '',
    requiredPersonnel: '1',
    rhythm: '0 0 * * *',
    weekday: getCurrentWeekday(),
    description: ''
  };
  const [inputFields, setInputFields] = useState<InputFieldsState>(initialInputFieldsState);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showWeekdayDropdown, setShowWeekdayDropdown] = useState(false);
  const { createTask } = useTask();

  const handleSubmit = () => {
    const taskData = {
      name: inputFields.taskName,
      description: inputFields.description,
      requiredPersonnel: parseInt(inputFields.requiredPersonnel),
      rhythm: inputFields.rhythm,
      weekday: parseInt(inputFields.weekday)
    };

    createTask(taskData)
      .then(_ => {
        feedbackToast({
          variant: 'success',
          message: 'Ämtli wurde hinzugefügt.',
          ctaMessage: 'Rückgängig machen?',
          toastId: 'TaskAdded'
        });
        setInputFields(initialInputFieldsState);
        setShowWeekdayDropdown(false)
      })
      .catch(error => {
        feedbackToast({
          variant: 'warn',
          message: 'Dieses Ämtli gibt es bereits',
          toastId: 'TaskNotAdded'
        });
      });
  };

  useEffect(() => {
    const { taskName, requiredPersonnel, weekday, rhythm } = inputFields;
    const isComplete =
      taskName.trim() !== '' &&
      requiredPersonnel.trim() !== '' &&
      weekday.trim() !== '' &&
      rhythm.trim() !== '';

    setIsFormComplete(isComplete);
  }, [inputFields]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setInputFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));

    if (name === 'rhythm') {
      setShowWeekdayDropdown(value !== '0 0 * * *');
      if (value === '0 0 * * *') {
        setInputFields(prevFields => ({
          ...prevFields,
          weekday: getCurrentWeekday()
        }));
      }
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full max-w-4xl px-4 pt-2 mt-8 sm:p-6 lg:p-8 p-6 bg-theme-300 rounded-2xl shadow-lg relative">
        <div className="absolute top-4 right-4">
          <ExitButton onClick={onClose} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 my-3 select-none">
          <InputField
            type="text"
            label="Name des Ämtlis"
            placeholder="Name hinzufügen..."
            size={Size.OneLine}
            onChange={handleInputChange}
            value={inputFields.taskName}
            name="taskName"
          />
          <Dropdown
            label="Rhythmus"
            id="rhythm"
            name="rhythm"
            options={[
              { value: '0 0 * * *', label: 'jeden Tag' },
              { value: '0 0 * * 0', label: 'jede Woche' },
              { value: '0 0 * * 0/2', label: 'alle 2 Wochen' }
            ]}
            onChange={handleInputChange}
            value={inputFields.rhythm}
          />
          <Dropdown
            label="Anzahl Personen"
            id="requiredPersonnel"
            name="requiredPersonnel"
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' }
            ]}
            onChange={handleInputChange}
            value={inputFields.requiredPersonnel}
          />
          {showWeekdayDropdown && (
            <Dropdown
              label="Wochentag"
              id="weekday"
              name="weekday"
              options={[
                { value: '1', label: 'Montag' },
                { value: '2', label: 'Dienstag' },
                { value: '3', label: 'Mittwoch' },
                { value: '4', label: 'Donnerstag' },
                { value: '5', label: 'Freitag' }
              ]}
              onChange={handleInputChange}
              value={inputFields.weekday}
            />
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mt-6">
          <InputField
            type="textarea"
            label="Beschreibung"
            placeholder="Beschreibung hinzufügen..."
            size={Size.MultipleLines}
            onChange={handleInputChange}
            value={inputFields.description}
            name="description"
          />
          {isFormComplete && <SaveButton onClick={handleSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default NewTaskPopup;
