import React from 'react';

const LoadingSpinner = () => (
  <div className="w-full h-full bg-theme-50 flex items-center justify-center">
    <div className="w-24 h-24 animate-spin-custom relative">
      <img
        className="w-full h-full object-contain"
        src="/images/TIE_Logo_Black.png"
        alt="Loading…"
      />
    </div>
  </div>
);

export default LoadingSpinner;
