import React, {useEffect, useState} from 'react';
import TaskPopup from './taskPopup';
import {
  SchoolDay,
  ScheduledTask,
  useAuth,
  useSchoolDay,
  useSchedule,
  useAbsence
} from '../../common';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

const getWeekDays = (numberOfWeeks: number) => {
  const weekDays = [];
  for (let weekOffset = 0; weekOffset < numberOfWeeks; weekOffset++) {
    const startOfCurrentWeek = new Date();
    startOfCurrentWeek.setDate(
      startOfCurrentWeek.getDate() -
        startOfCurrentWeek.getDay() +
        weekOffset * 7 +
        1
    );
    const week = [];
    for (let dayOffset = 0; dayOffset < 5; dayOffset++) {
      const date = new Date(startOfCurrentWeek);
      date.setDate(startOfCurrentWeek.getDate() + dayOffset);
      week.push(date);
    }
    weekDays.push(week);
  }
  return weekDays;
};

const Calendar = () => {
  const [myAbsences, setMyAbsences] = useState<Date[]>([]);
  const [myScheduledTasks, setMyScheduledTasks] = useState<ScheduledTask[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<ScheduledTask[]>([]);
  const [mySchoolDays, setMySchoolDays] = useState<SchoolDay>();

  const {userId} = useAuth();
  const {getMyAbsences} = useAbsence();
  const {getSchedule} = useSchedule();
  const {getMySchoolDays} = useSchoolDay();

  useEffect(() => {
    getMyAbsences().then(data => setMyAbsences(data));
  }, []);

  useEffect(() => {
    getSchedule().then(tasks => {
      const filtered = tasks.filter(task =>
        task.apprentices.some(apprentice => apprentice.userId === userId));
      setMyScheduledTasks(filtered);
    });
  }, [userId])

  useEffect(() => {
    getMySchoolDays().then(schoolDays => setMySchoolDays(schoolDays));
  }, [])

  const getMonthsOfCalendar = (weeks: Date[][]) => {
    const months = new Set(
      // Map each date to its month name
      weeks.flat().map(date => dayjs(date).format('MMMM'))
    );
  
    // Convert Set to array and join them with a slash if there are multiple months
    return Array.from(months).join('/');
  };  

  const weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr'];
  const allWeekDays = getWeekDays(3);

  const currentMonth = getMonthsOfCalendar(allWeekDays);

  const renderDateWithCircle = (date: Date) => {
    const hasTask = myScheduledTasks.some(
      task => dayjs(task.date).format('DD-MM-YYYY') === dayjs(date).format('DD-MM-YYYY')
    );
    const isAbsent = myAbsences.some(
      absence => dayjs(absence).format('DD-MM-YYYY') === dayjs(date).format('DD-MM-YYYY')
    );
    const isSchoolDay = getIsSchoolDay(date);

    const [circleColor, numberColor] = hasTask ?
      ['bg-theme-50 hover:bg-theme-50/70 hover:scale-[97%] hover:text-lg transition-all duration-100', 'text-theme-300'] :
      isAbsent || isSchoolDay ?
        ['bg-theme-950', 'text-theme-50'] :
        ['bg-transparent', 'text-theme-50'];

    const scheduledTasksOfDay = myScheduledTasks.filter(
      task => task.date.getDate() === date.getDate()
    );
    const circleClasses = hasTask ? 'hover:cursor-pointer' : '';

    const handleCircleClick = () => {
      if (scheduledTasksOfDay.length > 0) {
        setSelectedTasks(scheduledTasksOfDay);
      }
    };

    return (
      <div
        key={`${date.getMonth()}-${date.getFullYear()}-${date.getDate()}`}
        className={`items-center flex justify-center h-10 text-xl ${circleClasses}`}
        onClick={handleCircleClick}
      >
        <div className={`w-9 h-9 rounded-full ${circleColor} flex items-center justify-center`}>
          <div className={`${numberColor}`}>{date.getDate()}</div>
        </div>
      </div>
    );
  };

  const getIsSchoolDay = (date: Date) => {
    if (!mySchoolDays) return false;

    const isSameDay = (date1: Date, date2: Date) =>
      dayjs(date1).isSame(dayjs(date2), 'day');

    const dayIndex = date.getDay();
    const isExemptionDay = mySchoolDays.exemptions.some(exemption =>
      isSameDay(exemption, date));

    if (isExemptionDay || dayIndex < 1 || dayIndex > 5) {
      return false;
    } else {
      const daysOfWeek: Array<'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri'> = [
        'Mon', 'Tue', 'Wed', 'Thu', 'Fri'
      ];
      const currentDayString = daysOfWeek[dayIndex - 1];
      return !mySchoolDays.schoolDays[`is${currentDayString}Available`];
    }
  };

  return (
    <div className='bg-theme-50 h-full flex justify-center pt-5'>
      <div className='bg-theme-300 rounded-3xl p-10 h-fit shadow-xl'>
        <h2 className='text-theme-50 font-bold mb-10 text-4xl'>Meine Ämtlis - {currentMonth}</h2>
        <div className='grid grid-cols-6 gap-2'>
          {['', ...weekdays].map((day, index) => (
            <div
              key={index}
              className='text-center text-theme-50 pb-3 w-32 text-3xl font-medium'
            >
              {day}
            </div>
          ))}

          {/* Render calendar weeks and dates */}
          {allWeekDays.map((week, weekIndex) => (
            <React.Fragment key={weekIndex}>
              {/* Render calendar week */}
              <div className='text-center flex justify-center items-center font-medium text-theme-50 w-32 text-base'>
                KW {dayjs().add(weekIndex, 'week').week()}
              </div>
              {/* Render week days */}
              {week.map(date => renderDateWithCircle(date))}
            </React.Fragment>
          ))}
        </div>
      </div>
      {selectedTasks.length > 0 && (
        <TaskPopup
          onClose={() => setSelectedTasks([])}
          scheduledTasks={selectedTasks}
        />
      )}
    </div>
  );
};

export default Calendar;
