import React, { useState } from 'react';
import TaskOverview from './taskOverview';
import NewTaskPopup from './newTaskPopup';

const CreateNewTaskView = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="bg-theme-50 h-full">
      {isPopupVisible ? (
        <NewTaskPopup onClose={togglePopup} />
      ) : (
        <TaskOverview onNewTaskClick={togglePopup} />
      )}
    </div>
  );
};

export default CreateNewTaskView;
