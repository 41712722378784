import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

interface PageButtonProps {
  direction: 'prev' | 'next';
  onClick: () => void;
  disabled: boolean;
}

const PageButton = ({direction, disabled, onClick}: PageButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`p-2 bg-theme-50 rounded font-bold text-3xl 
        ${disabled ? 'text-theme-100' : 'text-theme-300 rounded-full outline-none transition-all duration-200 ease-in hover:bg-theme-200/10'}`}
    >
      {direction === 'prev' ? (
        <ChevronLeftIcon className="w-8 h-8"/>
      ) : (
        <ChevronRightIcon className="w-8 h-8"/>
      )}
    </button>
  );
};

export default PageButton;
