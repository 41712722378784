import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import {SchoolDay, Task, ScheduledTask} from '../domains';
import { User } from './auth';
import utc from 'dayjs/plugin/utc'
dayjs.extend((utc))

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL || ''}/api`,
  headers: {
    // We can not use useAuth here and get the access token, because
    // the useAuth hook uses react useState hook. The useState hook can only be
    // used by an react component otherwise it will throw an error.
    Authorization: `Bearer ${Cookies.get('access_token')}`
  }
})

export const useAbsence = () => {
  const getMyAbsences = () => {
    return apiClient.get<Date[]>('absence/me')
      .then(response => response.data)
  };

  const getAbsences = (apprenticeId: string) => {
    return apiClient.get<Date[]>(`absence/${apprenticeId}`)
      .then(response => response.data)
  };

  const addAbsence = (apprenticeId: string, date: Date) => {
    return apiClient.patch(`absence/${apprenticeId}`, {
      date
    })
      .then(response => response.data)
  };

  // Updates all absences of the apprentice
  const updateApprenticeAbsences = (apprenticeId: string, dates: Date[]) => {
    return apiClient.put(`absence/${apprenticeId}`, {absences: dates.map(date => dayjs(date).utcOffset(0).startOf('day').add(1, 'day').toDate())})
      .then(response => response.data);
  };
  return {
    getMyAbsences,
    getAbsences,
    addAbsence,
    updateApprenticeAbsences
  };
}

export const useSchoolDay = () => {
  const getMySchoolDays = () => {
    return apiClient.get<SchoolDay>('/schoolDay/me')
      .then(response => response.data)
  };

  const getSchoolDays = (apprenticeId: string) => {
    return apiClient.get<SchoolDay>(`/schoolDay/${apprenticeId}`)
      .then(response => response.data)
  };

  const updateSchoolDays = (schoolDay: SchoolDay) => {
    return apiClient.put<SchoolDay>('/schoolDay/', {...schoolDay, exemptions: schoolDay.exemptions.map(date => dayjs(date).utcOffset(0).startOf('day').add(1, 'day').toDate())})
      .then(response => response.data)
  };

  return {
    getMySchoolDays,
    getSchoolDays,
    updateSchoolDays
  }
}

export const useTask = () => {
  const createTask = (task: Task) => {
    return apiClient.post<Task>('task', task)
      .then(response => response.data);
  };

  const getAllTasks = () => {
    return apiClient.get<Task[]>('task')
      .then(response => {
        return response.data.map(task => task.name);
      });
  };

  getAllTasks().then(tasks => console.log(tasks));

  return {
    createTask,
    getAllTasks
  };
};

export const useSchedule = () => {
  const getSchedule = () => {
    return apiClient.get<ScheduledTask[]>('schedule')
      .then(response => {
        return response.data.map(task => {
          return {
            ...task,
            date: dayjs(task.date).toDate()
          }
        })
      })
  };

  return {
    getSchedule
  }
}

export const usePersonnel = () => {
  // Returns a list of emails of available but not registered,
  // only used for autocompletion.
  const getUnregisteredPersonnel = () => {
    return apiClient.get<string[]>('personnel')
      .then(response => response.data)
  };

  return {
    getUnregisteredPersonnel
  }
}

export const useApprentice = () => {
  const getApprentices = () => {
    return apiClient.get<User[]>('apprentice')
      .then(response => response.data)
  };

  const addApprentice = (email: string) => {
    return apiClient.post('apprentice', {email})
      .then(response => response.data)
  };

  return {
    getApprentices,
    addApprentice
  }
}

