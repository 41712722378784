import React, { useState, useRef, useEffect } from 'react';

interface UserSearchProps {
  emails: string[];
  value: string;
  onChange: (selectedUser: string) => void;
}

const UserSearch = ({ emails, value, onChange }: UserSearchProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(emails);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSelect = (selectedEmail: string) => {
    onChange(selectedEmail);
    setShowOptions(false);
  };

  const updateFilteredOptions = debounce((searchValue: string) => {
    setFilteredOptions(
      emails.filter(email =>
        email.toLowerCase().includes(searchValue.toLowerCase()))
    );
  }, 0);

  useEffect(() => {
    if (showOptions) {
      updateFilteredOptions(value);
    }
  }, [emails, value, showOptions]);

  useEffect(() => {
    setFilteredOptions(emails);
  }, [emails])

  return (
    <div className="relative">
      <input
        type="search"
        placeholder="Person wählen..."
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        onFocus={() => {
          setShowOptions(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            if (document.activeElement !== inputRef.current) {
              setShowOptions(false);
            }
          }, 200);
        }}
        ref={inputRef}
        className="block p-2 mx-auto text-black border rounded-2xl w-96 focus:outline-none bg-theme-50 hover:bg-theme-50/90"
      />
      {showOptions && filteredOptions && 0 < filteredOptions.length && (
        <ul
          className="absolute z-10 mt-2 bg-white bg-opacity-90 border text-black
        border-gray-300 rounded-md shadow-md w-96 text-left left-1/2 transform
         -translate-x-1/2 overflow-y-auto max-h-40"
        >
          {filteredOptions.map(email => (
            <li
              key={email}
              onClick={() => handleSelect(email)}
              className="px-3 py-1 cursor-pointer hover:bg-gray-200"
            >
              {email}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserSearch;
