import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const Forbidden = () => {
  return (
    <div className="bg-theme-50 h-screen">
      <div className="flex">
        <div className="container mx-auto">
          <div className="lg:mx-48 md:mx-12">
            <div className="bg-center bg-clip-border p-8 bg-theme-200 rounded-[2.2rem] relative">
              <div className="flex flex-col mt-5 items-center">
                <div>
                  <ExclamationTriangleIcon className="h-32 sm:h-50 w-32 text-theme-950" />
                </div>
                <div className="text-theme-950 font-secondary text-5xl">
                  Hoppla!
                </div>
                <div className="text-theme-950 mt-3 mb-2 font-secondary text-center text-2xl">Du hast eine gesperrte Seite erreicht.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Forbidden;
