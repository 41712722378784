import React, { useState } from 'react';
import {
  ClipboardDocumentListIcon,
  UserCircleIcon
} from '@heroicons/react/24/solid';
import { UserRole, ScheduledTask, useAuth } from '../../common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface TaskCardProps {
  scheduledTask: ScheduledTask;
  handleReplaceAbsenteeAssignment: (userId: string, date: Date) => void;
}

const TaskCard = ({
  scheduledTask,
  handleReplaceAbsenteeAssignment
}: TaskCardProps) => {
  const [disabledButtons, setDisabledButtons] = useState<{ [key: string]: boolean; }>({});
  const { roles } = useAuth();

  const handleAbsentTodayButtonClick = (user_id: string, date: Date) => {
    setDisabledButtons(prevState => ({
      ...prevState,
      [user_id]: true
    }));
    handleReplaceAbsenteeAssignment(user_id, dayjs(date).utcOffset(0).startOf('day').add(1, 'day').toDate());
    // The 'add 1' code above is some black magic we currently need to mitigate time zone issues. Without dayjs and
    // + 1 we would always save the previous day at 10pm to db, instead of the actual day.
  };

  return (
    <div className="bg-theme-300 rounded-3xl p-4 shadow-lg w-80 text-theme-50 h-full flex flex-col justify-between">
      <div>
        <div className="mb-4 mt-2 justify-center text-center items-center flex w-full h-10">
          {/* Replace this with the right icons at later date */}
          <ClipboardDocumentListIcon className="h-full" />
        </div>
        <h2 className="text-xl font-primary h-10 font-bold mb-2">
          {scheduledTask.taskName}
        </h2>
        <div className={'relative overflow-x-hidden overflow-y-scroll max-w-fit h-56 transition-all duration-300 max-h-[6.5rem] pr-2'}
        >
          {scheduledTask.taskDescription}
        </div>
      </div>
      <div className="space-y-2 mt-auto h-32 flex flex-col justify-end">
        {scheduledTask.apprentices &&
          scheduledTask.apprentices.map(apprentice => (
            <div key={apprentice.userId} className="flex justify-between items-center">
              <div className="flex items-center">
                <UserCircleIcon className="w-6 h-6" />
                <span className="ml-2 font-secondary leading-tight">
                  {apprentice.userName}
                </span>
              </div>
              {roles.some(role => role === UserRole.Educator) ? (
                <button
                  type="button"
                  disabled={disabledButtons[apprentice.userId]}
                  onClick={() =>
                    handleAbsentTodayButtonClick(
                      apprentice.userId,
                      scheduledTask.date
                    )
                  }
                  className={`inline-block rounded bg-theme-300 text-theme-50
                    px-2 py-1 text-xs font-medium leading-normal w-20 h-8
                    transition-all duration-150 ease-in-out hover:bg-theme-950/20 active:ring-2
                    ring-theme-50/25 ring-1 ${
                disabledButtons[apprentice.userId] ? 'opacity-50' : ''
                }`}
                >
                  Abwesend
                </button>
              ) : (
                <></>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TaskCard;
