import React from 'react';
import { ActiveView } from './header';

interface HeaderItemProps {
  active: boolean;
  icon: React.ReactNode;
  text: string;
  activeView: ActiveView;
}

function getIconTextColor (active: boolean) {
  switch (true) {
  case active:
    return 'text-theme-50';
  default:
    return 'text-theme-300';
  }
}

function getBackgroundColor (active: boolean) {
  switch (true) {
  case active:
    return 'bg-theme-300';
  default:
    return '';
  }
}

export function HeaderItem ({ active, icon, text }: HeaderItemProps) {
  const iconTextColor = getIconTextColor(active);
  const backgroundColor = getBackgroundColor(active);

  return (
    <div className={`p-4 ${backgroundColor} rounded-lg flex flex-col items-center ${active ? '' : 'transition-all duration-200 hover:bg-theme-200/10'}`}>
      <div className={`font-secondary ${iconTextColor}`}>{icon}</div>
      <div className={`font-secondary ${iconTextColor}`}>{text}</div>
    </div>
  );
}
