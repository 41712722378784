import React from 'react';

export const Statistics = () => {
  return (
    <div className="bg-theme-50 h-full">
      <div className="flex items-center justify-center h-full">
        <div className="px-5 py-2 font-primary mb-12
      text-theme-300/50 rounded-lg border-theme-300/50 border-dashed border select-none">
          Dieses Feature kommt bald!
        </div>
      </div>
    </div>
  );
};
