import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../enums/userRoles';

interface JwtPayload {
  provider: string;
  id: string;
  email: string;
  firstName: string;
  familyName: string;
  gtoken: string;
  roles: UserRole[];
  location: string[];
}

export interface User {
  userId: string;
  userEmail: string;
  roles: UserRole[];
  userName: string;
}

export function useAuth (): User & {
  login: () => void;
  logout: () => void;
  getAccessToken: () => string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  } {
  const [user, setUser] = useState<User>({
    userId: '',
    userEmail: '',
    roles: [],
    userName: ''
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = getAccessToken();

    if (accessToken) {
      const decodedToken = decodeToken(accessToken);
      if (decodedToken) {
        setUser({
          userId: decodedToken.id,
          userEmail: decodedToken.email,
          roles: decodedToken.roles || [],
          userName: `${decodedToken.firstName} ${decodedToken.familyName}`
        });
        setIsAuthenticated(true);
      } else {
        // If token can't be decoded, reset user state to unauthenticated state
        setUser({
          userId: '',
          userEmail: '',
          roles: [],
          userName: ''
        });
        setIsAuthenticated(false);
      }
    } else {
      // If there is no token, reset user state to unauthenticated state
      setUser({
        userId: '',
        userEmail: '',
        roles: [],
        userName: ''
      });
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  const getAccessToken = () => {
    return Cookies.get('access_token');
  }

  const login = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL || ''}/api/auth/login`;
  }, []);

  const logout = useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL || ''}/api/auth/logout`;

    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    }).then(_ => {
      Cookies.remove('access_token');

      setIsAuthenticated(false);
      setUser({
        userId: '',
        userEmail: '',
        roles: [],
        userName: ''
      });

      navigate('/login');
    });
  }, [navigate]);

  return { ...user, isAuthenticated, isLoading, login, logout, getAccessToken };
}

function decodeToken (token: string): JwtPayload | null {
  let decoded: JwtPayload | null = null;

  try {
    decoded = jwtDecode<JwtPayload>(token);
  } catch (error) {
    console.error('Error decoding token:', error);
  }

  return decoded;
}
