import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'
import DatePicker from './datepicker';
import { ScheduledTask, useAbsence, useSchedule } from '../../common';
import ScheduledTaskContainer from './scheduledTaskContainer';
import { feedbackToast } from '../components/toasts';
dayjs.extend(weekOfYear);

export const HomePage = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs().startOf('day')
  );
  const [selectedWeek, setSelectedWeek] = useState<number>(0)
  const [scheduledTasks, setScheduledTasks] = useState<ScheduledTask[]>([]);

  const {addAbsence} = useAbsence();
  const {getSchedule} = useSchedule();
  
  useEffect(() => {
    getSchedule().then(data => setScheduledTasks(data));
  }, []);

  const handleUpdateSingleAbsence = (userId: string, date: Date): void => {
    addAbsence(userId, date)
      .then(newScheduledTasks => {
        const oldScheduledTasks = scheduledTasks.filter(
          task => !dayjs(task.date).isSame(dayjs(date), 'day')
        );
        setScheduledTasks([...oldScheduledTasks, ...newScheduledTasks]);
      })
      .then(() => {
        feedbackToast({
          variant: 'success',
          message: 'Abwesenheit wurde hinzugefügt.',
          ctaMessage: 'Rückgängig machen?',
          toastId: 'AbsenceAdded'
        });
      });
  };

  const getMonthOfTheWeek = () => {
    const startOfWeek = dayjs().add(selectedWeek, 'week').startOf('week')
    const endOfWeek = dayjs().add(selectedWeek, 'week').endOf('week')

    const startMonth = startOfWeek.format('MMMM')
    const endMonth = endOfWeek.format('MMMM')

    if (startMonth !== endMonth) {
      return `${startMonth}/${endMonth}`
    } else {
      return startMonth
    }
  }

  return (
    <div className="bg-theme-50 h-full">
      <div className='text-center mb-3 text-3xl tracking-wide font-bold font-primary text-theme-300'>
        {`${getMonthOfTheWeek()}, KW ${dayjs().add(selectedWeek, 'week').week()}`}
      </div>
      <DatePicker onDateChange={date => setSelectedDate(date)} onWeekChange={week => setSelectedWeek(week)}/>
      <ScheduledTaskContainer
        filteredScheduledTasks={scheduledTasks.filter(task =>
          dayjs(task.date).isSame(selectedDate, 'day'))
        }
        handleUpdateSingleAbsence={handleUpdateSingleAbsence}
        selectedDate={selectedDate.toDate()}
      />
    </div>
  );
};
