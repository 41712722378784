import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { InfoBoxColors } from '../../common/enums/infoBoxColors';

interface InfoBoxProps {
  label: string;
  bgColor: InfoBoxColors;
}

export const InfoBox = ({label, bgColor}: InfoBoxProps) => {
  return (
    <div className=' sm:px-3 px-3 place-content-center items-center flex'>
      <div className={`${bgColor} rounded-[2.2rem] h-64 p-6 mt-10 max-w-4xl`}>
        <div className='place-content-center items-center flex h-52 mt-1 md:px-14 sm:px-6 px-2'>
          <div>
            <ExclamationCircleIcon className='h-20 w-20 text-theme-50' />
          </div>
          <div className='ml-2 text-theme-50 font-secondary text-3xl text-center'>
            {label}
          </div>
        </div>
      </div>
    </div>
  );
}
