import React, { useState, useEffect, FormEvent } from 'react';
import SaveButton from '../components/saveButton';
import { feedbackToast } from '../components/toasts';
import { useSchoolDay, SchoolDay } from '../../common';

const CheckboxForm = ({ selectedUserId, selectedUserExemptions }:
  { selectedUserId: string, selectedUserExemptions: Date[] | undefined }) => {
  const { getSchoolDays, updateSchoolDays } = useSchoolDay();
  const [formData, setFormData] = useState<SchoolDay>({
    userId: '',
    schoolDays: {
      isMonAvailable: false,
      isTueAvailable: false,
      isWedAvailable: false,
      isThuAvailable: false,
      isFriAvailable: false
    },
    exemptions: []
  });

  useEffect(() => {
    if (selectedUserId) {
      getSchoolDays(selectedUserId)
        .then(response => {
          setFormData({
            userId: selectedUserId,
            schoolDays: response.schoolDays,
            exemptions: response.exemptions
          });

        })
        .catch(error => {
          feedbackToast({
            variant: 'error',
            message: 'Fehler beim abfragen der Daten',
            toastId: 'ErrorRequestingSchoolDays'
          });
        });
    }
  }, [selectedUserId]);

  const handleCheckboxChange = (day: keyof SchoolDay['schoolDays']) => {
    setFormData(prev => ({
      ...prev,
      schoolDays: {
        ...prev.schoolDays,
        [day]: !prev.schoolDays[day]
      },
      userId: selectedUserId,
      exemptions: selectedUserExemptions ? selectedUserExemptions : []
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updateSchoolDays(formData).then(_ =>
      feedbackToast({
        variant: 'success',
        message: 'Schultage erfolgreich gespeichert.',
        toastId: 'SchoolDaysSaved'
      })).catch(_ =>
      feedbackToast({
        variant: 'error',
        message: 'Schultage nicht gespeichert.',
        toastId: 'SchoolDaysNotSaved'
      }));
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-4">
      {selectedUserId && (
        <div className="flex items-center justify-center h-30 mt-5">
          {[
            { label: 'MO', name: 'isMonAvailable' },
            { label: 'DI', name: 'isTueAvailable' },
            { label: 'MI', name: 'isWedAvailable' },
            { label: 'DO', name: 'isThuAvailable' },
            { label: 'FR', name: 'isFriAvailable' }
          ].map(day => (
            <div
              key={day.name}
              className={
                'border-theme-200 border m-5 text-white flex items-center justify-center rounded-full ' +
                'w-20 h-20 cursor-pointer border-opacity-30 select-none' +
                (!formData.schoolDays[day.name as keyof SchoolDay['schoolDays']] ? ' bg-theme-950 border-theme-950' : '')
              }
              onClick={() => handleCheckboxChange(day.name as keyof SchoolDay['schoolDays'])}
            >
              <span className="text-xl">{day.label}</span>
            </div>
          ))}
        </div>
      )}
      {selectedUserId && (<SaveButton onClick={() => { ''; }} />)}
    </form>
  );
};

export default CheckboxForm;
