import { InfoBoxColors } from '../../common/enums/infoBoxColors';
import { InfoBox } from '../components/infoBox';

export const NotFoundPage = () => {
  return (
    <div className="bg-theme-50 h-full">
      <InfoBox label='404 Seite nicht gefunden' bgColor={InfoBoxColors.THEME_300} />
    </div>
  );
};
