import { useState, useEffect } from 'react';
import TaskCard from './scheduledTaskCard';
import PageButton from './pageButton';
import { ScheduledTask } from '../../common';
import { InfoBox } from '../components/infoBox';
import { InfoBoxColors } from '../../common/enums/infoBoxColors';

interface ScheduledTaskContainerProps {
  filteredScheduledTasks: ScheduledTask[];
  handleUpdateSingleAbsence: (user_id: string, date: Date) => void;
  selectedDate: Date;
}

const ScheduledTaskContainer = ({
  filteredScheduledTasks,
  handleUpdateSingleAbsence,
  selectedDate
}: ScheduledTaskContainerProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const scheduledTasksPerPage = 3;

  const totalPages = Math.ceil(
    filteredScheduledTasks.length / scheduledTasksPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDate]);

  const currentTasks = filteredScheduledTasks.slice(
    (currentPage - 1) * scheduledTasksPerPage,
    currentPage * scheduledTasksPerPage
  );

  return (
    <div>
      {filteredScheduledTasks.length === 0 ? (
        <InfoBox label='Heute sind keine Ämtli eingetragen.' bgColor={InfoBoxColors.THEME_300}/>
      ) : (
        <div className='flex flex-col items-center space-y-4 mt-10'>
          <div className='flex items-center space-x-4'>
            <PageButton
              direction='prev'
              onClick={handlePrevPage}
              disabled={currentPage <= 1}
            />
            <div className='flex space-x-4'>
              {currentTasks.map(scheduledTask => (
                <TaskCard
                  key={new Date().getMilliseconds().toString() + scheduledTask.taskId}
                  scheduledTask={scheduledTask}
                  handleReplaceAbsenteeAssignment={handleUpdateSingleAbsence}
                />
              ))}
              {Array.from({
                length: scheduledTasksPerPage - currentTasks.length
              }).map((_, index) => (
                <div key={index} className='w-80 h-96' />
              ))}
            </div>
            <PageButton
              direction='next'
              onClick={handleNextPage}
              disabled={currentPage >= totalPages}
            />
          </div>
          <div className='flex justify-center space-x-2'>
            {Array.from({length: totalPages}, (_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index + 1 === currentPage ? 'bg-theme-300' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduledTaskContainer;
