import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';

interface ExitButtonProps {
  onClick: () => void;
}

const ExitButton = ({ onClick }: ExitButtonProps) => {
  return (
    <button onClick={onClick} className="exit-button-class">
      <XMarkIcon className="text-theme-50 h-9 w-9 outline-none rounded-full transition-all duration-200 ease-in hover:bg-theme-950/50"/>
    </button>
  );
};

export default ExitButton;
