import React, {useState} from 'react';
import {
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/solid';
import {ScheduledTask, useAuth} from '../../common';

interface TaskPopupProps {
  onClose: () => void;
  scheduledTasks: ScheduledTask[];
}
const TaskPopup = ({onClose, scheduledTasks}: TaskPopupProps) => {
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const {userId, userName} = useAuth();
  const buttonClass =
    'relative top-1/2 transform -translate-y-1/2 text-white rounded';
  const chevronStyle = 'h-12 w-12';

  const nextTask = () => {
    setCurrentTaskIndex(prevIndex =>
      scheduledTasks.length <= prevIndex + 1 ? 0 : prevIndex + 1);
  };

  const previousTask = () => {
    setCurrentTaskIndex(prevIndex =>
      prevIndex <= 0 ? scheduledTasks.length - 1 : prevIndex - 1);
  };

  const renderTask = (scheduledTask: ScheduledTask) => {
    return (
      <div key={scheduledTask.taskId}>
        <div className="pb-6">
          <p>
            {scheduledTask.date.toLocaleDateString('de-DE', {
              day: '2-digit',
              month: 'long'
            })}
          </p>
          <p>
            <strong>{scheduledTask.taskName}</strong>
          </p>
        </div>
        <p>
          <strong>{'Du (' + userName + ')'}</strong>
          {scheduledTask.apprentices.map(
            apprentice =>
              apprentice.userId !== userId && (
                <React.Fragment key={apprentice.userId}>
                  <br />
                  {apprentice.userName}
                </React.Fragment>
              )
          )}
        </p>
      </div>
    );
  };

  const TaskIndicator = () => (
    <div className="flex justify-center mt-4">
      {scheduledTasks.map((_, index) => (
        <div
          key={index}
          className={`w-1.5 h-1.5 mx-1 rounded-full ${
            index === currentTaskIndex ? 'bg-theme-50' : 'bg-theme-200'
          }`}
        ></div>
      ))}
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-theme-950 text-theme-50 py-3 rounded-2xl relative">
        <button
          className="absolute top-0 right-0 text-white py-4 px-4 rounded"
          onClick={onClose}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <div className="flex min-w-72">
          {scheduledTasks.length > 1 ? (
            <>
              <div className="flex-none">
                <button
                  onClick={previousTask}
                  className={`${buttonClass} left-0`}
                >
                  <ChevronLeftIcon className={`${chevronStyle}`} />
                </button>
              </div>
              <div className="flex-auto p-5">
                {renderTask(scheduledTasks[currentTaskIndex])}
              </div>
              <div className="flex-none">
                <button onClick={nextTask} className={`${buttonClass} right-0`}>
                  <ChevronRightIcon className={`${chevronStyle}`} />
                </button>
              </div>
            </>
          ) : (
            <div className="flex-auto py-5 px-9">
              {renderTask(scheduledTasks[currentTaskIndex])}
            </div>
          )}
        </div>
        {scheduledTasks.length > 1 && <TaskIndicator />}
      </div>
    </div>
  );
};

export default TaskPopup;
