import toast from 'react-hot-toast';
import React from 'react';
import { ExclamationTriangleIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

interface ToastWithCtaProps {
  variant: string;
  onClickFunction?: () => void;
  message: string;
  ctaMessage?: string;
  toastId?: string;
}

const variantIconMapping: { [key: string]: React.ReactNode } = {
  success: <CheckCircleIcon className="size-8 text-theme-300 mr-2" />,
  warn: <ExclamationTriangleIcon className="size-8 text-toast-30 mr-2" />,
  error: <ExclamationCircleIcon className="size-8 text-toast-40 mr-2" />
};

export function feedbackToast ({ variant, onClickFunction, message, ctaMessage, toastId }: ToastWithCtaProps) {
  const feedbackToastIcon = variantIconMapping[variant];
  toast.custom(t => {
    return (
      <div className={`${t.visible ? 'animate-enter' : 'animate-leave'}
      max-w-lg w/full h-full bg-toast-10 rounded-lg pointer-events-auto
      flex items-center justify-center text-center ring-1 ring-toast-20 shadow-md`}>
        <button
          onClick={onClickFunction}
          className="min-w-full min-h-full border border-transparent rounded-lg
  p-4 flex items-center justify-center text-center text-md text-toast-text
  focus:outline-none focus:ring-0 focus:ring-theme-950"
        >
          <span>{feedbackToastIcon}</span>
          <span className={'font-medium'}>{message}</span>
          <span className={'ml-1 text-theme-300 font-bold'}>{ctaMessage}</span>
        </button>
      </div>
    );
  }, { id: toastId });
}
